import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap"
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';
import Select from 'react-select';
const Add_category = () => {
    let userDetail = localStorage.getItem('ship_rocket_user');
    // console.log("userDetail====", config.UserId);
    let userData = {};
    if (userDetail != null) {
      userData = JSON.parse(userDetail)
    }
    // const isOnline = useNetworkState();
    // console.log("isONline  === " ,isOnline )
    const [defaultCountry, setDefaultCountry] = React.useState({country_id:0})
    const [selectedOption, setSelectedOption] = React.useState(null);
    const [options, setOptions] = React.useState([]);
    const [modalState , setModalState] = React.useState({show:false})
    const [btnState , setbtnState] = React.useState(true)

    const [countryList, setcountryList] = React.useState([]);
    const [stateList, setStateList] = React.useState([]);
    const [cityList, setCityList] = React.useState([]);
    const [pincodeList, setPincodeList] = React.useState([]);
    const[addressState, setaddressState] = React.useState([]);
    const [addstate,setaddState] = React.useState({category_name:"", err:{} });
    // console.log("addstate ===== " , addstate)
    const[country,setCountry] = React.useState({country_id:""})
    const[state,setState] = React.useState({state_id:""})
    const[city,setCity] = React.useState({city_id:""})
    const[editmodal , seteditmodal] = React.useState({show:false , category_name:"" , err:{}})
    // console.log("editmodal===== " , editmodal)
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
React.useEffect(()=>{
  
    address_book_detail()
},[])
   
      
    

    const handleChange =(e)=>{
       setaddState({...addstate, [e.target.name]:e.target.value , err:{}})
       
    }

    const addAddress = (value) => {
      setbtnState(false)
        // console.log("value",value)
        let url = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + '/add_category';
           let sendData =  addstate ;
          
          
           if(addstate.category_name!=""    ){
           axios.post(url, sendData, { headers: exportValue.headers }).then((res) => {
             if(res.data.status==true){
                setModalState({...modalState,show:false})
                setbtnState(true)
                setOptions([])
                setSelectedOption(null)
                address_book_detail()
                const Toast = Swal.mixin({
                    toast: true,
                    position: 'bottom-end',
                    showConfirmButton: false,
                    timer: 3000
                  })
                  
                  Toast.fire({
                    background:"#206bc4",
                    type: 'success',
                    title: "Category Added Successfully",
                    color:"white"
                  });
                
             }
             else{
              setbtnState(true)
             }
           }).catch((e) => {
            setModalState({...modalState,show:false})

            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
              setbtnState(true)
           });
        }
        
        else{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Please Fill Category Name",
                color:"white"
              });
              setbtnState(true)
        }
    
       }

       const address_book_detail = ()=>{
        let url = exportValue.host + "/" + exportValue.version + "/" + exportValue.api  + '/category_list';
        let sendData = { };
        // console.log("bb", sendData);
        axios.post(url,sendData ,{headers:exportValue.headers}).then((res)=>{
            if(res.data.status==true){
                // console.log("sasdfdsa")
                setaddressState(res.data.output)
                setState([])
                setCityList([])
                setPincodeList([])
                setaddState({...addstate, category_name:"" ,  err:{} })
                setOptions([])

                 
            }

        }).catch(()=>{
            const Toast = Swal.mixin({
                toast: true,
                position: 'bottom-end',
                showConfirmButton: false,
                timer: 3000
              })
              
              Toast.fire({
                background:"rgb(231, 91, 75)",
                type: 'unsuccess',
                title: "Something Went Wrong",
                color:"white"
              });
       
           
        })
       }

    //    const delete_address_book = (value)=>{
    //     let url =  exportValue.host + "/" + exportValue.version + "/" + exportValue.api + '/delete_store_warehouse';
    //     let sendData = { address_id:value };
    //     axios.post(url , sendData ,{headers:exportValue.headers}).then((res)=>{
    //         if(res.data.status==true){
    //             address_book_detail()
    //             const Toast = Swal.mixin({
    //                 toast: true,
    //                 position: 'bottom-end',
    //                 showConfirmButton: false,
    //                 timer: 3000
    //               })
                  
    //               Toast.fire({
    //                 background:"#206bc4",
    //                 type: 'success',
    //                 title: "Deleted Successfully",
    //                 color:"white"
    //               });
    //         }

    //     }).catch(()=>{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
              
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'unsuccess',
    //             title: "Something Went Wrong",
    //             color:"white"
    //           });
    //     })
    //    }

    //    const setAsPrimary =(value)=>{
    //     let url = exportValue.apiUrl + '/country/update_address_book_primary_status';
    //     let sendData = {  customer_id: userData.customer_id , address_id:value };
    //     axios.post(url , sendData ,{headers:exportValue.headers}).then((res)=>{
    //         if(res.data.status==true){
    //             address_book_detail()
    //             const Toast = Swal.mixin({
    //                 toast: true,
    //                 position: 'bottom-end',
    //                 showConfirmButton: false,
    //                 timer: 3000
    //               })
                  
    //               Toast.fire({
    //                 background:"rgb(231, 91, 75)",
    //                 type: 'success',
    //                 title: "Update Successfully",
    //                 color:"white"
    //               });
    //         }

    //     }).catch(()=>{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
              
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'unsuccess',
    //             title: "Something Went Wrong",
    //             color:"white"
    //           });
    //     })
    //    }

       const handleEditChange = (event) => {
        // event.persist()
         // console.log("event is v",event.target.name);
         seteditmodal({
             ...editmodal,
             [event.target.name]: event.target.value,
         })
     }


//      const Seteditaddress = async(sub)=>{
//       console.log("sub ==" , sub)
//       seteditmodal({show:true, address_id:sub.address_id,full_name:sub.warehouse_name , country_name:sub.country_name ,state_name:sub.state_name , city_name:sub.city_name , pincode:sub.pincode , address:sub.address , email:sub.email , mobile:sub.mobile ,  country_id:sub.country_id , state_id:sub.state_id , city_id:sub.city_id })
//       // getStateList(sub.country_id)
     
//         try {
//           // // console.log("value", value);
//           let url = exportValue.apiUrl + '/country/pincode_list';
//           let sendData = { city_id: sub.city_id };
//           // console.log("bb", sendData);
          
//           const response = await axios.post(url, sendData, { headers: exportValue.headers });
          
//           // setPincodeList(response.data.output);
//           let data = response.data.output;
//           const transformedOptions = data.map(item => ({
//             value: item.postal_code,
//             label: item.postal_code
//           }));
//           // // console.log("transformedOptions == ", transformedOptions);
//           setOptions(transformedOptions);
//           const matchedOption = transformedOptions.find(option => option.value === sub.pincode);
//           // // console.log("matchedOption",matchedOption)
//           if (matchedOption) {
//             setSelectedOption(matchedOption);
//           }
          
       
//         } catch (error) {
//           // console.error(error);
//           throw error; 
//         }
      
//    }

    //  const editAddress =()=>{
    //     // // console.log("value",value)
    //     let url =exportValue.host + "/" + exportValue.version + "/" + exportValue.api + '/edit_store_warehouse';
    //        let sendData =  editmodal;
    //        // console.log("bb", sendData);
    //        let re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

    //        if ( re.test(editmodal.email) ) {
    //        if(editmodal.full_name!="" && editmodal.country_name!="" && editmodal.state_name!="" && editmodal.city_name!="" && editmodal.address!="" && editmodal.pincode!="" && editmodal.email!="" && editmodal.mobile!=""  ){
    //        axios.post(url, sendData, { headers: exportValue.headers }).then((res) => {
    //          if(res.data.status==true){
    //             seteditmodal({...editmodal,show:false})
    //             setSelectedOption(null);
    //             setOptions([])
    //             setState([]);
    //             setCityList([]);
    //             setPincodeList([]);
    //             setCountry({country_id:""});
    //             setState({state_id:""});
    //             setCity({city_id:""});
    //             address_book_detail()
    //             const Toast = Swal.mixin({
    //                 toast: true,
    //                 position: 'bottom-end',
    //                 showConfirmButton: false,
    //                 timer: 3000
    //               })
                  
    //               Toast.fire({
    //                 background:"#206bc4",
    //                 type: 'success',
    //                 title: "Address Updated Successfully",
    //                 color:"white"
    //               });
                
    //          }
    //        }).catch((e) => {
    //         seteditmodal({...editmodal,show:false})

    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
              
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'unsuccess',
    //             title: "Something Went Wrong",
    //             color:"white"
    //           });
       
    //        });
    //     }
        
    //     else{
    //         const Toast = Swal.mixin({
    //             toast: true,
    //             position: 'bottom-end',
    //             showConfirmButton: false,
    //             timer: 3000
    //           })
              
    //           Toast.fire({
    //             background:"rgb(231, 91, 75)",
    //             type: 'unsuccess',
    //             title: "Please Fill All the Details",
    //             color:"white"
    //           });
    //     }
    // }else{
    //     let itemsR = {...editmodal};
    //     itemsR.err = {};
    //       itemsR.err["email"] = true;   
    //      seteditmodal(itemsR)
    // }
       
    //  }

    

       const onhide=()=>{
        setModalState({ show: false })
        setaddState({...addstate, category_name:""  , err:{} })
         setSelectedOption(null)
        setOptions([])
        setState([])
        setCityList([])
        setPincodeList([])
        setCountry({country_id:""})
        setState({state_id:""})
        setCity({city_id:""})

       }
  return (
    <div >
    <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
          
          <div className={`home-section ${isSidebarOpen ? '' : 'sidebar-closed'}`}>
             
              <Sub_header/>
        <Modal show={editmodal.show} onHide={() => seteditmodal({ show: false })} size='lg'>
     <Modal.Header closeButton>
                         <Modal.Title>Edit Category</Modal.Title>
                     </Modal.Header>
                     <Modal.Body >
                     <div className='row'>
                         <div className='col-6'>
                             <label>category Name <span class="text-danger">*</span></label>
                             <input className='form-control mt-2' type='text' placeholder='Enter Category Name' name="category_name" autoComplete='off' onChange={(e)=>handleEditChange(e)} value={editmodal.category_name}/>
                         </div>
                       
                      
                     </div>
                    
    
                   
                    
                     </Modal.Body>
                     <Modal.Footer>
                     <Button variant="danger"
                    //   onClick={() => editAddress()}
                      >
                             Submit
                         </Button>
                       <Button variant="primary" onClick={() => seteditmodal({ show: false })}>
                             Close
                         </Button>
                                  
                     </Modal.Footer>
                 </Modal>  
                 <Modal show={modalState.show} onHide={() => onhide()} size='lg'>
     <Modal.Header closeButton>
                         <Modal.Title>
                       
             <h4 class="modal-title">Add Category</h4>
           
        
                         </Modal.Title>
                     </Modal.Header>
                     <Modal.Body >
                     <div className='row'>
                         <div className='col'>
                             <label>Category Name <span class="text-danger">*</span></label>
                             <input className='form-control mt-2' type='text' placeholder='Enter Category Name' name="category_name" autoComplete='off' onChange={(e)=>handleChange(e)}/>
                         </div>
                      
                   
                    
                     </div>
                   
           <div class="p-3 text-center">
           {btnState==true?  <a  class="btn btn-primary" onClick={() => addAddress()}>Add Category</a>:""}
           </div>
                     </Modal.Body>
                   
                </Modal> 
        <div class="row mb-2">
            <div class="col-12 col-md">
                <div class="d-flex">
                    {/* <div class="back_btn_wrap">
                        <a href="" class="back_btn">
                        <CornerUpLeft/>
                        </a>
                    </div> */}
                    <div class="">
                        <h2>Category</h2>
                    </div>
                </div>
            </div>
            <div class="col-12 col-md-5 text-end btns">
                <a  onClick={() => setModalState({ show: true })}class="btn btn-primary mt-2" data-bs-toggle="modal" data-bs-target="#Warehouse_add">Add Category</a>
            </div>
        </div>
        
        <div class="card card-body border-0 shadow table-wrapper table-responsive">
           <table class="table table-hover carr_list_ad">
                            <thead>
                            <tr class="">
                                <th>Category ID</th>
                                <th>Category Name</th>
                             
                            </tr>
                            </thead>
                            <tbody>
                            {addressState.map((sub)=>(
                            <tr class="">
                                <td>
                                    #{sub.category_id}
    
                                   
                                </td>
                                <td>
                                    <strong>{sub.category_name}</strong>
                                </td>
                              
                                {/* <td>
                            <div class="btn-group">
                                <button class="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                    <span class="icon icon-sm">
                                        <span class="fa fa-ellipsis-h icon-dark"></span>
                                    </span>
                                    <span class="visually-hidden">Toggle Dropdown</span>
                                </button>
                                <div class="dropdown-menu py-0">
                                    <a class="dropdown-item rounded-top" href="#" data-bs-toggle="modal" data-bs-target="#add_update_carrier" onClick={()=>Seteditaddress(sub)}><span class="fa fa-eye me-2"></span>View/Update</a>
                                    
                                    <a class="dropdown-item text-danger rounded-bottom" onClick={()=>delete_address_book(sub.address_id)}><span class="fa  fa-trash me-2"></span>Remove</a>
                                    <a class="dropdown-item  rounded-top" href={`/allot_warehouse_to_customer/${sub.address_id}`}><span class="fa fa-eye me-2"></span>Allot Warehouse To Customer</a>
                                    <a class="dropdown-item  rounded-top" href={`/allot_warehouse_to_store_keeper/${sub.address_id}`}><span class="fa fa-eye me-2"></span>Allot Warehouse To Store Keeper</a>
                                  
                                </div>
                            </div>
                        </td> */}
                            </tr>
                            ))}
                            </tbody>
                        </table>
                   
              
           
        </div>
        
    
        
    
    
        
    </div>
    {/* <!-- Main content area END --> */}
    </div>
  )
}

export default Add_category
