import React, { useState, useEffect } from "react";
import axios from "axios";
import Modal from 'react-bootstrap/Modal';
import Swal from 'sweetalert2'
import { Container, Row, Col, Form, Button, ProgressBar } from "react-bootstrap";
import ReactLoading from 'react-loading';
import exportValue from "../../apiconfig";
import Left_panel from '../Left_panel';
import Sub_header from '../Sub_header';
import '../../components/loader.css';
import { useNavigate, useParams } from 'react-router-dom'
import TablePagination from '@mui/material/TablePagination';




const State_list = () => {
    const { country_id } = useParams();
    console.log("country_id => ",country_id);
    let navigate = useNavigate();
    const search1 = window.location.search;
    const params = new URLSearchParams(search1);
    let search= params.get('search')
    const [state, setState] = useState([]);
    const [countryInfo, setCountryinfo] = useState({country_name:""});
    const [modalState, setModalState] = useState({ show: false });
    const [editModalState, setEditModalState] = useState({ show: false });
    const [uploadModalState, setUploadModalState] = useState({ show: false,csv_fle:"" });
    const [newState, setNewState] = useState({ state_name: "",state_code:""});
    const [editState, setEditState] = useState({ state_name: "",state_id:"",state_code:"" });
    const [otherStates,setOtherState] = useState({dstatus:"",activePage:1,rowsPerPage:25,page:0,total_count:0,onload:true});
    const [searchfilters, setsearchFilters] = useState({ searchtitle: "" })
    const [isLoading, setIsLoading] = useState(false);
    const [isSidebarOpen, setIsSidebarOpen] = React.useState(false);

    const toggleSidebar = () => {
        setIsSidebarOpen(!isSidebarOpen);
        const homeSection = document.querySelector('.home-section');
        if (homeSection) {
            homeSection.classList.toggle('sidebar-closed');
        }
    };
    const Toast = Swal.mixin({
        toast: true,
        position: 'bottom-end',
        showConfirmButton: false,
        timer: 5000
    })

    useEffect(() => {
        getStateList(0,true,otherStates.rowsPerPage);
    }, [])

    const getStateList = (index=0,onLoad,rowsPerPage) => {

        setIsLoading(true)
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/state_list`;
        let sendData = {searchvalue:"",country_id:country_id,limit:rowsPerPage,indexValue:index};
        console.log("search ", search);
        if(search!=null && search.length>0){
            sendData.searchvalue= search
        }else if(searchfilters.searchtitle != "") {
            sendData.searchvalue= searchfilters.searchtitle
        }
        console.log("bb", sendData);
        axios.post(full_api, sendData, { headers: exportValue.headers }).then((res) => {
            console.log("res ", res);
            setIsLoading(false)
            if(index == 0 && onLoad) {
                setOtherState({...otherStates,total_count:res.data.count,rowsPerPage:rowsPerPage})         

            }
            setState(res.data.output,{isLoading:false})
            setCountryinfo({country_name:res.data.country_name})


        }).catch((e) => {
            setIsLoading(false)

        });
    }
    const handlePageChange = (event,newPage) =>{
        setOtherState({...otherStates,page:newPage})
        //console.log("newPage ", newPage);
        getStateList(newPage,true,otherStates.rowsPerPage);
    }
    const handleChangeRowsPerPage = (event) => {
        console.log("event ", event.target.value);
        setOtherState({...otherStates,rowsPerPage:event.target.value})
        //console.log("newPage ", newPage);
        getStateList(0,true,event.target.value);
    }

    // const searchState = (e) => {
    //     console.log(e.target.value);
    //     let countryBackup = countryList;
    //     if (e.target.value != "") {
    //         let countryArray = [];
    //         countryBackup.map((item) => {
    //             if (item.country.toLowerCase().search(e.target.value.toLowerCase()) != -1) {
    //                 console.log(item.country.search(e.target.value));

    //                 countryArray.push(item)

    //             }
    //             setState(countryArray)
    //         })
    //     } else {
    //         setState(countryList)
    //     }
    // }

    const inputHandleChange = (e) => {
        console.log("e", e);
        console.log("type", e.target.type);
        console.log("name", e.target.name);
        console.log("value", e.target.value);
        console.log("file", e.target.files);
        if (e.target.type == "file") {
            setNewState({ ...newState, [e.target.name]: e.target.files });
        } else {
            setNewState({ ...newState, [e.target.name]: e.target.value });
        }
    }

    const inputEditHandleChange = (e) => {
        console.log("e", e);
        console.log("type", e.target.type);
        console.log("name", e.target.name);
        console.log("value", e.target.value);
        console.log("file", e.target.files);
        if (e.target.type == "file") {
            setEditState({ ...editState, [e.target.name]: e.target.files });
        } else {
            setEditState({ ...editState, [e.target.name]: e.target.value });
        }
    }

    const csvinputEditHandleChange = (e) => {        
        if (e.target.type == "file") {            
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.files });
        } else {
            setUploadModalState({ ...uploadModalState, [e.target.name]: e.target.value });
        }
    }

    const newCountrysave = () => {
        try {
            if (newState.state_name != "") {
                let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/add_state`;
                // let bodyFormData = new FormData()
                // console.log("bb", newState);
                // bodyFormData.append("country", newState.state_name)
                // bodyFormData.append("mob_country_code", newState.mob_country_code)

                // if (newState.flag != '') {
                //     bodyFormData.append("country_flage", newState.flag[0]);
                // }
                let saveData = {state_name:newState.state_name,country_id:country_id,state_code:newState.state_code};
                console.log("bb", saveData);
                axios.post(full_api, saveData, {headers: exportValue.headers
                }).then((res) => {
                    console.log("res ", res);

                    setModalState({ show: false });
                    setNewState({ ...newState, state_name: "", flag: "" })

                    setTimeout(
                        getStateList(0,true), 5000);
                    if (res.data.status == "success") {
                        Swal.fire(
                            'Good job!',
                            ' Added Successfully !',
                            'success'
                        )
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: res.data.message,

                        })
                    }

                    // navigate('/categories')
                    // let newCategory = res.data.category;
                    //setState(state.concat([newCategory]))
                }).catch((e) => {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Please fill fields!',

                })

            }
        } catch (e) {
            console.log(e);
        }
    }

    const deleteConfirm = (state_id) => {
        Swal.fire({
            title: 'Are you sure?',
            text: "You won't be able to revert this!",
            type: 'warning',
            showCancelButton: true,
            confirmButtonColor: '#3085d6',
            cancelButtonColor: '#d33',
            confirmButtonText: 'Yes, delete it!'
        }).then((result) => {
            //console.log("result  ",result);
            if (result.isConfirmed) {
                stateDelete(state_id)
            }
        })

    }

    const edit_state = (data) => {
        setEditState({ state_name: data.state_name, state_id: data.state_id,country_id:data.country_id,state_code:data.state_code });
        setEditModalState({ show: true })

    }

    const stateDelete = (state_id) => {
        try {
            if (country_id != "") {
                let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/delete_state_api`;
                let bodyFormData = { state_id:state_id,country_id: country_id }

                console.log("bb", bodyFormData);
                axios.post(full_api, bodyFormData, { headers: exportValue.headers }).then((res) => {
                    console.log("res ", res);



                    setTimeout(
                        getStateList(0,true), 5000);
                    if (res) {
                        Swal.fire(
                            'Good job!',
                            ' Deleted Successfully !',
                            'success'
                        )
                    }
                    else {
                        Swal.fire({
                            icon: 'error',
                            title: 'Oops...',
                            text: 'Something went wrong!',

                        })
                    }

                    // navigate('/categories')
                    // let newCategory = res.data.category;
                    //setState(state.concat([newCategory]))
                }).catch((e) => {

                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                });
            } else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'something went wrong!',

                })

            }
        } catch (e) {
            console.log(e);
        }
    }

    const newCountryModel = () => {
        setNewState({ state_name: "", flag: "" });
        setModalState({ show: true })
    }

    const handleChange = (e,data) =>{
        console.log("id---> ", data);

        console.log("e---> ", e.target.name);
        console.log("e----> ", e.target.checked);
        countryUpdate({country_id:data.country_id,state_id:data.state_id,status:(e.target.checked)? 1 : 2})
    }

    const editStateValue = () => {
        if (editState.state_name != "" && editState.state_id != "") {
        countryUpdate(editState)
    } else {
        Swal.fire({
            icon: 'error',
            title: 'Oops...',
            text: 'Please fill fields!',

        })

    }
    }
    const countryUpdate = (bodyData) => {

        
            let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/edit_state`;
            // let bodyFormData = new FormData()
            // console.log("bb", editState);
            // bodyFormData.append("country", editState.country)
            // bodyFormData.append("country_id", editState.country_id)
            // bodyFormData.append("mob_country_code", editState.mob_country_code)

            // if (editState.flag != undefined &&editState.flag != '') {
            //     bodyFormData.append("country_flage", editState.flag[0]);
            // }
            console.log("bb", bodyData);
            axios.post(full_api, bodyData, {
                headers:exportValue.headers
            }).then((res) => {
                console.log("res ", res);

                setEditModalState({ show: false });
                setEditState({ ...editState, state_name: "", state_id: "" })

                setTimeout(
                    getStateList(0,true), 5000);
                if (res) {
                    Swal.fire(
                        'Good job!',
                        'Updated Successfully !',
                        'success'
                    )
                }
                else {
                    Swal.fire({
                        icon: 'error',
                        title: 'Oops...',
                        text: 'Something went wrong!',

                    })
                }

                // navigate('/categories')
                // let newCategory = res.data.category;
                //setState(state.concat([newCategory]))
            }).catch((e) => {

                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            });
       

    }

    const uploadPincodeFile= () => {

        if (uploadModalState.csv_fle != undefined && uploadModalState.csv_fle != '') {
           // var patt1 = /\.([0-9a-z]+)(?:[\?#]|$)/i;
            let fileName = uploadModalState.csv_fle[0].name;
           // console.log("fileName =======>                 ",fileName);
           
           // console.log("file =======>                 ",fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0]);
           // return 
            if(fileName.match(/\.([0-9a-z]+)(?:[\?#]|$)/i)[0] == ".csv"){
        let full_api = exportValue.host + "/" + exportValue.version + "/" + exportValue.api + `/upload_file_pincode`;
         let bodyFormData = new FormData()
        // console.log("bb", editState);
        // bodyFormData.append("country", editState.country)
         bodyFormData.append("country_id", country_id)
        // bodyFormData.append("mob_country_code", editState.mob_country_code)

        
        bodyFormData.append("csvFile", uploadModalState.csv_fle[0]);
         
       // let bodyData = {country_id:country_id,csvFile:uploadModalState.csv_fle}
        console.log("bb", bodyFormData);
        axios.post(full_api, bodyFormData, {
            headers:exportValue.headers
        }).then((res) => {
            console.log("res ", res);

            setUploadModalState({...uploadModalState, show: false });
          //  setEditState({ ...editState, state_name: "", state_id: "" })

           
            if (res.status == 200 && res.data.status == "success") {
               // setTimeout(getStateList(0,true), 5000);
                Toast.fire({
                    background: "#206bc4",
                    type: 'success',
                    title: "Succesfully uploaded",
                    color: "white"
                });
            }
            else {
                Swal.fire({
                    icon: 'error',
                    title: 'Oops...',
                    text: 'Something went wrong!',

                })
            }

            // navigate('/categories')
            // let newCategory = res.data.category;
            //setState(state.concat([newCategory]))
        }).catch((e) => {

            Swal.fire({
                icon: 'error',
                title: 'Oops...',
                text: 'Something went wrong!',

            })
        });
    }else {
        Toast.fire({
            background: "#8a2be2",
            type: 'error',
            title: "Please choose CSV file!",
            color: "white"
        });
    }
    }else {
             Toast.fire({
                    background: "#10b93b",
                    type: 'error',
                    title: "Please choose a file!",
                    color: "white"
                });
    }
   

}

   

const stateHandler = (e) => {
    // state[key] = value
    if (e.target.name == "stitle") {
      
            params.delete('search')
            search= null;
          setsearchFilters({ ...searchfilters, searchtitle: e.target.value })
            //   navigate({ search: params.toString() })

        }
//    setsearchFilters({ ...searchfilters, searchtitle:searchfilters.searchtitle})
    setOtherState({...otherStates,onload:true})
}
const filter=()=>{
    search=null
    params.delete('search')
    if (searchfilters.searchtitle !== "") {           
        params.append('search', searchfilters.searchtitle)
    }
   
    // setState({ ...state, isLoading: true })
    navigate({ search: params.toString() })
    getStateList(0)
}
React.useEffect(()=>{
    if (search) {
        setsearchFilters({...searchfilters,searchtitle:search})
    }



},[])

const searchOnkeyPress = (e) =>{
    console.log("e=>    ",e.charCode);
    if(e.charCode == 13) {
        filter()
    }
}


    return (
        <div>
            <Modal show={modalState.show} onHide={() => setModalState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>New State</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="email">State Name</label>
                            <input class="form-control" id="first_name" type="text" placeholder="Enter name" name="state_name" onChange={(e) => inputHandleChange(e)} required />
                            <span>*Please fill </span>
                        </div>
                       <div class="col-md-12 mb-3">
                            <label for="email">State Code</label>
                            <input class="form-control" id="first_name" type="text" placeholder="Enter code" name="state_code" onChange={(e) => inputHandleChange(e)} required />

                        </div>
                        {/*  <div class="col-md-12 mb-3">
                            <label for="email">Country Flag</label>
                            <input class="form-control" id="first_name" type="file" name="flag" onChange={(e) => inputHandleChange(e)} />
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            newCountrysave()
                        }>
                        Save
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={editModalState.show} onHide={() => setEditModalState({ show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Edit State</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="email">State Name</label>
                            <input class="form-control" id="first_name" type="text" placeholder="Enter name" name="state_name" onChange={(e) => inputEditHandleChange(e)} required value={editState.state_name} />
                            <span>*Please fill </span>
                        </div>
                         <div class="col-md-12 mb-3">
                            <label for="email">State Code</label>
                            <input class="form-control" id="first_name" type="text" placeholder="Enter code" name="state_code" onChange={(e) => inputEditHandleChange(e)} required value={editState.state_code} />

                        </div>
                      {/*  <div class="col-md-12 mb-3">
                            <label for="email">Country Flag</label>
                            <input class="form-control" id="first_name" type="file" name="flag" onChange={(e) => inputEditHandleChange(e)} />
                        </div> */}
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            editStateValue()
                        }>
                        Update
                    </Button>
                </Modal.Footer>
            </Modal>

            <Modal show={uploadModalState.show} onHide={() => setUploadModalState({...uploadModalState, show: false })}>
                <Modal.Header closeButton>
                    <Modal.Title>Upload file</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="row">
                        <div class="col-md-12 mb-3">
                            <label for="email">Select csv file</label>
                            <input class="form-control" id="first_name" type="file" placeholder="Enter name" name="csv_fle" onChange={(e) => csvinputEditHandleChange(e)}  />
                            <span>* File should be .csv </span>
                        </div>
                        <div class="col-md-12 mb-3">
                        
                    <Button variant="primary"
                        onClick={() =>
                            uploadPincodeFile()
                        }>
                        Upload
                    </Button>
                    </div>
                         <div class="col-md-12 mb-3">
                            <label for="email">Sample file CSV</label>
                            <a href="../../../assets/pincode_upload.csv" className="nav-link dropdown-toggle"> <i className="material-icons-outlined">download_outline</i> </a>
                            <span>Download file</span>
                            {/* <Button variant="secondary"
                        onClick={() =>
                            editStateValue()
                        }>
                        Dowload file
                    </Button> */}
                        </div>
                      {/*  <div class="col-md-12 mb-3">
                            <label for="email">Country Flag</label>
                            <input class="form-control" id="first_name" type="file" name="flag" onChange={(e) => inputEditHandleChange(e)} />
                        </div> */}
                    </div>
                </Modal.Body>
                {/* <Modal.Footer>
                    <Button variant="secondary" onClick={() => setEditModalState({ show: false })}>
                        Cancel
                    </Button>
                    <Button variant="primary"
                        onClick={() =>
                            editStateValue()
                        }>
                        Update
                    </Button>
                </Modal.Footer> */}
            </Modal>
           

                  
                    {(isLoading) ?
                <div style={{ backgroundColor: "#808080", zIndex: 2, height: "100%", width: "100%", position: "absolute", opacity: "0.5" }}>
                    <div style={{ left: "47%", top: "45%", position: "absolute" }}>
                        <ReactLoading type={"spin"} color={"#000000"} height={300} width={89} />
                    </div>
                </div> : ""}
                <Left_panel isSidebarOpen={isSidebarOpen} toggleSidebar={toggleSidebar} />
       
       <section class="home-section">
               {/* <?php
               include('sub_header.php');
               ?> 
            */}
       <Sub_header/>
                        <div className="d-flex justify-content-between flex-wrap flex-md-nowrap align-items-center py-4">
                            <div className="d-block mb-4 mb-md-0">
                                <nav aria-label="breadcrumb" className="d-none d-md-inline-block">
                                    <ol className="breadcrumb breadcrumb-dark breadcrumb-transparent">
                                        <li className="breadcrumb-item">
                                            <a href="#">
                                                <svg className="icon icon-xxs" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 12l2-2m0 0l7-7 7 7M5 10v10a1 1 0 001 1h3m10-11l2 2m-2-2v10a1 1 0 01-1 1h-3m-6 0a1 1 0 001-1v-4a1 1 0 011-1h2a1 1 0 011 1v4a1 1 0 001 1m-6 0h6"></path></svg>
                                            </a>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">{countryInfo.country_name}</li>
                                    </ol>
                                </nav>
                                <h2 className="h4">States</h2>
                            </div>
                            <div className="btn-toolbar mb-2 mb-md-0">
                                <div className="input-group me-2 me-lg-3 fmxw-400">
                                    <span className="input-group-text">
                                        <svg className="icon icon-xs" x-description="Heroicon name: solid/search" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true" onClick={(e) => filter(e)}>
                                            <path fillRule="evenodd" d="M8 4a4 4 0 100 8 4 4 0 000-8zM2 8a6 6 0 1110.89 3.476l4.817 4.817a1 1 0 01-1.414 1.414l-4.816-4.816A6 6 0 012 8z" clip-rule="evenodd"></path>
                                        </svg>
                                    </span>
                                    <input type="text" className="form-control" placeholder="Search State" name="stitle" value={searchfilters.searchtitle} onChange={(e)=>stateHandler(e)} onKeyPress={(event) => searchOnkeyPress(event)}/>
                                </div>
                                <a className="btn btn-sm btn-gray-800 d-inline-flex align-items-center" onClick={() => newCountryModel()}>
                                    <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                    New State
                                </a>
                            </div>
                        
                        <a className="btn btn-sm btn-gray-800 d-inline-flex align-items-center" onClick={() => setUploadModalState({...uploadModalState,show:true})}>
                                    <svg className="icon icon-xs me-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M12 6v6m0 0v6m0-6h6m-6 0H6"></path></svg>
                                    Upload States with Pincodes
                                </a>
                                </div>

                    

                        <div className="card card-body border-0 shadow table-wrapper table-responsive">
                            <table className="table table-hover">
                                <thead>
                                    <tr>
                                       
                                        <th className="border-gray-200">State Name</th>
                                        <th className="border-gray-200">State Code</th>
                                        <th className="border-gray-200">City Count</th>
                                        <th className="border-gray-200">Status</th>
                                       
                                       
                                        <th className="border-gray-200">Action</th>
                                    </tr>
                                </thead>
                                <tbody>

                                    {state.map((item, index) => {
                                        return (
                                            <tr>
                                                {/* <td>
                                                    <a href="#" className="fw-bold">
                                                        <img src={"https://shippxcdn.fniix.com/flags/" + item.country_flage} className="img-fluid country_flg" />
                                                    </a>
                                                </td> */}
                                                <td><span className="fw-bold"><a href={"/city_list/"+country_id+"/"+item.state_id}>{item.state_name}</a></span></td>
                                                <td><span className="fw-bold">{item.state_code}</span></td>
                                                <td><span className="fw-bold">{item.city_count}</span></td>
                                                
                                                
                                                {/* <td>{(item.active == 1) ? <span className="badge rounded-pill bg-success">Active</span> : <span className="badge rounded-pill bg-warning">Inactive</span>}</td> */}
                                                 <td>
                                <div class="form-check form-switch  mb-2">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" name="status" defaultChecked={item.status == 1} onChange={(e) => handleChange(e,item)}/>
                                </div>
                            </td>
                            {/* <td>
                                <div class="form-check form-switch  mb-2">
                                    <input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" checked/>
                                </div>
                            </td> */}
                                                <td>
                                                    <div className="btn-group">
                                                        <button className="btn btn-link text-dark dropdown-toggle dropdown-toggle-split m-0 p-0" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                                            <span className="icon icon-sm">
                                                                <span className="fa fa-ellipsis-h icon-dark"></span>
                                                            </span>
                                                            <span className="visually-hidden">Toggle Dropdown</span>
                                                        </button>
                                                        <div className="dropdown-menu py-0">
                                                            {/* <a className="dropdown-item rounded-top" href="#"><span className="fa fa-eye me-2"></span>View Details</a> */}
                                                            <a className="dropdown-item" onClick={() => edit_state(item)}><span className="fa fa-edit me-2"></span>Edit</a>
                                                            <a className="dropdown-item text-danger rounded-bottom" onClick={() => deleteConfirm(item.state_id)}><span className="fa  fa-trash me-2"></span>Remove</a>
                                                        </div>
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                               
                            </table>
                            <TablePagination
                component="div"
                rowsPerPageOptions={[25,50]}
                count={otherStates.total_count}
                page={otherStates.page}
                onPageChange={handlePageChange}
                rowsPerPage={otherStates.rowsPerPage}
                onRowsPerPageChange={handleChangeRowsPerPage}
               
                        />
                            {/* <div className="card-footer px-3 border-0 d-flex flex-column flex-lg-row align-items-center justify-content-between">
                                <nav aria-label="Page navigation example">
                                    <ul className="pagination mb-0">
                                        <li className="page-item">
                                            <a className="page-link" href="#">Previous</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">1</a>
                                        </li>
                                        <li className="page-item active">
                                            <a className="page-link" href="#">2</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">3</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">4</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">5</a>
                                        </li>
                                        <li className="page-item">
                                            <a className="page-link" href="#">Next</a>
                                        </li>
                                    </ul>
                                </nav>
                                <div className="fw-normal small mt-4 mt-lg-0">Showing <b>5</b> out of <b>25</b> entries</div>
                            </div> */}
                        </div>


                    </section>
               

            </div>
        

    )
}
export default State_list