import React from 'react'
import { useLocation, useParams } from 'react-router-dom'
import axios from "axios";
import Moment from 'react-moment';

import ReactLoading from 'react-loading';
import exportValue from '../../apiconfig'
import Barcode from 'react-barcode';

const Print_receipts = () => {
    const location = useLocation()
    const {shipment_id} = useParams()
    console.log("shipment_id",shipment_id)

  

    const[state,setState]= React.useState({shipment_list:[]})
    console.log("state",state)
    const[dimensionState,setdimensionState] = React.useState({companyDetail:[]})


    React.useEffect(() => {
      dimension_detail()
      shipment_detail()
    
      }, [])

    const shipment_detail=()=>{
        let dataToSend = {shipment_id:shipment_id };
   
      console.log("datatoSend",dataToSend)
      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/shipment_detail`;
     
     // console.log("headers => ", config.headers);
     
      
      axios.post(full_api, dataToSend, { headers: exportValue.headers })
          .then((res) => {
            console.log("responseJson => ", res);
            setState({shipment_list:res.data.output})
          
  
         
          })
          .catch((error) => {
              //Hide Loader
            //   setLoadingstate({...loadingstate,loading:false})
              console.log(error);
          });    

    }
    const dimension_detail = () =>{

      let full_api = exportValue.host + '/' + exportValue.version + '/' + exportValue.api + `/company_info_api`;
      let sendData = {};
    
      axios.post(full_api, sendData, { headers: exportValue.headers }).then(res => {
        setdimensionState({...dimensionState,companyDetail:res.data.companyDetail});
        console.log("rnmmmmm", res);
      }).catch((e) => {
        // toast.configure()
         //toast.error("Some thing went wrong")
        console.log("----error:   ", e);
      })
    }
//  const  FunctionComponent = () => {
//     return <Barcode value={shipment_id} />;
// };

return (
    <div>
       {state.shipment_list.map((sub)=>(
        <section class="mb-3 pt-5 bg-light p-5">
       <div class="container print_receipt_cs bg-white p-5">
    <div class="row">
    <div class="col-6 col-md-6">
        <h1>{dimensionState.companyDetail.map((subscriber)=>(subscriber.company_name))}</h1>
        <p>
        {dimensionState.companyDetail.map((subscriber)=>(subscriber.company_address))}<br/>
            {/* Hamburg, Germany - 20355 */}
        </p>
        <p>
            Ph:  {dimensionState.companyDetail.map((subscriber)=>(subscriber.mob_number))}
        </p>
       </div>
       <div class="col text-end">
        <h2>Invoice </h2>
          <table class="table table-bordered">
            <tbody>
              <tr>
              </tr><tr>
                <td>Invoice #</td>
                <td><h5>{sub.shipment_id}</h5></td>
              </tr>
                <tr><td>Date</td>
                <td><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> </td>
              </tr>
             
            </tbody>
          </table>
       </div>
    </div>
    
    <div class="row">
   
        <div class="col-6 col-md-6">
         
          <h6>Invoice Bill To</h6>
          <h2>{sub.receiver.name}</h2>
          <p>
           {sub.receiver.address}<br/>
          {sub.receiver.city}, {sub.receiver.country} - {sub.receiver.pincode}
          </p>
          <p>
              Ph: {sub.receiver.mobile}
          </p>
          
        </div>
        
       <div class="col-6 col-md-6 text-end">
        <div class="card pt-5 border-0">
          <div class="card-body">
            <h2 class="fw-700 text-danger">{sub.amount_paid==true?"PAID":"UNPAID"}</h2>
          </div>
        </div>
       </div>
    </div>
    <hr/>
    <div class="row pt-5">
        <div class="col-12">
         <h5>Billing information</h5>
            <table class="table table-bordered">
             <thead>
                <tr>
                        <th>Order date</th>
                        <th>type</th>
                        <th>carrier name</th>
                        <th>service name</th>
                        <th>total weight</th>
                        <th>no of item</th>
                        <th>shipping fee</th>
                </tr>
             </thead>
             <tbody>
                <tr>
                        <td><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> <br/>
                            <small><Moment format="hh:mm">
                        {new Date(sub.created*1000)}
            </Moment></small>
                        </td>
                        <td>{sub.type}</td>
                        <td>{sub.carrier_name}</td>
                        <td>{sub.service_provider}</td>
                        <td>{sub.total_weight!=undefined && sub.total_weight!=""?sub.total_weight:""}({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</td>
                        <td>{sub.items.length}</td>
                        <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.rate_price}</td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Total</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.rate_price}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Tax: VAT (18%)</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.tax_amount}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Insurance</td>
                    <td><strong>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.insurance_amount}</strong></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Grand Total</td>
                    <td><h6>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.total_amount}</h6></td>
                </tr>
                <tr>
                    <td colspan="6" class="text-end">Paid Amount:</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){sub.total_amount}</td>
                </tr>
             </tbody>
            </table>
        </div>
    </div>

    <div class="row pt-5">
        <div class="col col-md">
         <h5>Shipment Details </h5>
            <table class="table table-bordered">
                <thead>
                  <tr>
                    <th>items</th>
                    <th>invoice value</th>
                    <th>reference/order number</th>
                    <th>weight ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</th>
                    <th>quantity</th>
                    <th>price</th>
                    <th>total</th>
                  </tr>
                </thead>
                <tbody>
                  {sub.items.map((item)=>(
                  <tr>
                    <td>
                        Name: <strong>{item.item_name}</strong><br/>
                        Description: <strong>{item.item_description}</strong><br/>
                        SKU: <strong>{item.item_sku}</strong><br/>
                        Invoice number: <strong>{sub.order_number}</strong><br/>
                        Invoice date: <strong><Moment format="MMM DD YYYY">
                        {new Date(sub.created*1000)}
            </Moment> </strong><br/>
                        product_url: <strong>google.com</strong><br/>
                        Dim ({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_dimension}</>
                                 ))}): <strong>H{item.item_height}xW{item.item_width}xL{item.item_length}</strong>

                    </td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                    <td>{sub.order_number}</td>
                    <td>{item.item_weight}({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_weight}</>
                                 ))})</td>
                    <td>1</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                    <td>({dimensionState.companyDetail.map((subscriber)=>( 
                                  <>{subscriber.set_currency}</>
                                 ))}){item.item_value}</td>
                  </tr>
                  ))}
                </tbody>
              </table>
        </div>
    </div>

    <div class="row pt-5">
        <div class="col col-md">
            <h5>Pickup information</h5>
            <table class="table table-bordered">
              
                <tbody>
                  <tr>
                    <th>Sender Name</th>
                    <td>{sub.sender.name}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{sub.sender.address}</td>
                  </tr>
                  <tr>
                    <th>Pickup Time</th>
                    <td>N/A</td>
                  </tr>
                  <tr>
                    <th>Phone Number</th>
                    <td>{sub.sender.mobile}</td>
                  </tr>
                  <tr>
                    <th>Postal Code</th>
                    <td>{sub.sender.pincode}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{sub.sender.city}</td>
                  </tr>
                  {/* <tr>
                    <th>State</th>
                    <td>Florida</td>
                  </tr> */}
                  <tr>
                    <th>Country</th>
                    <td>{sub.sender.country}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{sub.sender.email}</td>
                  </tr>
                </tbody>
                
            </table>
        </div>
        <div class="col col-md">
            <h5>Delivery information</h5>
            <table class="table table-bordered">
            
                <tbody>
                    <tr>
                      <th>Receiver Name</th>
                      <td>{sub.receiver.name}</td>
                    </tr>
                    <tr>
                      <th>Address</th>
                      <td>{sub.receiver.address}</td>
                    </tr>
                    <tr>
                      <th>Delivery Time</th>
                      <td>N/A</td>
                    </tr>
                    <tr>
                      <th>Phone Number</th>
                      <td>{sub.receiver.mobile}</td>
                    </tr>
                    <tr>
                      <th>Postal Code</th>
                      <td>{sub.receiver.pincode}
                      </td>
                    </tr>
                    <tr>
                      <th>City</th>
                      <td>{sub.receiver.city}</td>
                    </tr>
                    {/* <tr>
                      <th>State</th>
                      <td>Surrey</td>
                    </tr> */}
                    <tr>
                      <th>Country</th>
                      <td>{sub.receiver.country}</td>
                    </tr>
                    <tr>
                      <th>Email</th>
                      <td>{sub.receiver.email}</td>
                    </tr>
                  </tbody>
        
            </table>
        </div>
        <div class="col col-md">
            <h5>Return information</h5>
            <table class="table table-bordered">
            <tbody>
                <tr>
                    <th> Name</th>
                    <td>{sub.sender.name}</td>
                  </tr>
                  <tr>
                    <th>Address</th>
                    <td>{sub.sender.address}</td>
                  </tr>
                
                  <tr>
                    <th>Phone Number</th>
                    <td>{sub.sender.mobile}</td>
                  </tr>
                  <tr>
                    <th>Postal Code</th>
                    <td>{sub.sender.pincode}</td>
                  </tr>
                  <tr>
                    <th>City</th>
                    <td>{sub.sender.city}</td>
                  </tr>
                 
                  <tr>
                    <th>Country</th>
                    <td>{sub.sender.country}</td>
                  </tr>
                  <tr>
                    <th>Email</th>
                    <td>{sub.sender.email}</td>
                  </tr>
                </tbody>
            </table>
        </div>
    </div>  
    
    <div class="row pt-5">
        <div class="col-12">
          <h6>Terms and conditions of shipment:</h6>
          <ul>
            <li>Goods not paid in full may not leave, please contact the agent</li>
            <li>Payment is always in advance whether paid </li>
            <li>Quoted delivery times are estimate and may vary</li>
            <li>Consignor declares that the consignment is free of any dangerous or hazardous goods as defined by regulatory bodies and by legislation</li>
            <li>Goods not picked within 7 days of offloading may be disposed off or charged storage</li>
            <li>Insufficiently packed goods shipped at owner's risk and we will not be held responsible</li>
            <li>Goods would only be presumed missing if not received 2 months from expected-by date</li>
            <li>Compensation payable for loss or damage is limited to the repair costs of the item damaged or, if lost or damaged beyond repair, the replacement costs taking account of depreciation</li>
            <li>Any claims for insured goods would have to be supported by original purchase receipts</li>
          </ul>
        </div>
    </div>
    <hr/>
    {/* <div class="row pt-5">
        <div class="col">
          <h6>Invoice Bank Details:</h6>
          
          <h6>ShipRocket Inc.</h6>
          <p>
            Account#: 298384834<br/>
            Sort Code: 293-3499<br/>
            IBAN: GB50MYMB23058044806924<br/>
            BIC: MSK93923<br/>
            Currency: USD<br/>
            Metro Bank<br/>
            Unit 8, B Park, UK
          </p>
        </div>
    </div> */}
</div>   
 </section> 

))}
  </div>
  
  )
}

export default Print_receipts



 